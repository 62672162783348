import { useFlags } from "launchdarkly-react-client-sdk";

import Dashboard from "../components/Dashboard";
import RequestsDashboard from "../components/RequestsDashboard/RequestsDashboard";
import RequestsDataGridDashboard from "../components/RequestsDashboard/RequestsDataGridDashboard";

export default function Home() {
  const { dataGridExperiment } = useFlags();
  return (
    <Dashboard>
      {dataGridExperiment ? (
        <RequestsDataGridDashboard />
      ) : (
        <RequestsDashboard />
      )}
    </Dashboard>
  );
}
