import { Requests_Bool_Exp } from "shared/generated/graphql";

export function getSearchFilters(search: string): Requests_Bool_Exp {
  if (!search) {
    return {};
  }

  const searchTerm = `%${search}%`;

  if (!isNaN(+search)) {
    return {
      _or: [
        { id: { _eq: Number(search) } },
        { vehicle: { year: { _ilike: searchTerm } } },
      ],
    };
  } else {
    return {
      _or: [
        { vehicle: { make: { _ilike: searchTerm } } },
        { vehicle: { model: { _ilike: searchTerm } } },
        { vehicle: { plate: { _ilike: searchTerm } } },
        { vehicle: { vin: { _ilike: searchTerm } } },
        {
          vehicle: {
            usersVehicles: {
              carNickname: { _ilike: searchTerm },
            },
          },
        },
        {
          vehicle: {
            fleetVehicles: {
              nickname: { _ilike: searchTerm },
            },
          },
        },
        {
          services: {
            description: { _ilike: searchTerm },
          },
        },
        {
          serviceAdvisor: {
            email: { _ilike: searchTerm },
          },
        },
      ],
    };
  }
}
